.stripe-card-element {
    font-family: 'Montserrat, Open Sans, sans-serif';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    border-radius: 12px;
    border: 1px solid #8db4e1;
    height: 52px;
    display: flex;
    align-items: center;
    padding: 0 10px;

    .StripeElement {
        width: 100%;
    }
}

.payment-type__button-inactive {
    filter: grayscale(0.3);
    opacity: 0.7;
}

.ant-form-item.subdomainField {
    @media (max-width: 425px) {
        .ant-input-wrapper {
            display: GRID;
            grid-template-columns: 79px 1fr;
            grid-template-rows: repeat(2, 1fr);
            :nth-child(1) {
                display: block;
                grid-area: 1 / 1 / 2 / 2;
                width: 100%;
                padding: 17px 0;
                border-radius: 0;
                border-start-start-radius: var(--ant-border-radius-lg);
            }
            :nth-child(2) {
                display: block;
                grid-area: 1 / 2 / 2 / 3;
                border-radius: 0;
                border-start-end-radius: var(--ant-border-radius-lg);
            }
            :nth-child(3) {
                display: block;
                grid-area: 2 / 1 / 3 / 3;
                width: 100%;
                padding: 17px 0;
                border-radius: 0;
                border-end-start-radius: var(--ant-border-radius-lg);
                border-end-end-radius: var(--ant-border-radius-lg);
                border-left: var(--ant-line-width) var(--ant-line-type) var(--ant-color-border);
                border-top: 0;
            }
        }
    }
}
