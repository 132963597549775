.app-page-container {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: stretch;
    overflow: hidden;
}

.ant-checkbox {
    align-self: flex-start;
    margin-top: 4px; /** TODO: very optional - recomend to remove this line */
}

.ant-radio-wrapper .ant-radio {
    align-self: flex-start !important;
    margin-top: 4px; /** TODO: very optional - recomend to remove this line */
}

.round-checkboxes {
    .ant-radio-wrapper {
        width: 100%;
        &:not(&:last-child) {
            margin-bottom: var(--ant-margin-xs);
        }
        width: 100%;
        border-width: var(--ant-line-width);
        border-style: var(--ant-line-type);
        padding: var(--ant-padding-lg);
        border-color:#D4E8FF !important;
        border-radius: var(--ant-border-radius-lg);

        &.ant-radio-wrapper-checked {
            color: var(--ant-color-primary) !important;
            border-color: var(--ant-color-primary) !important;
        }
    }

    // .ant-radio-inner {
    //     border-radius: 100%;
    //     // &:after {
    //     //     transform: translate(-20%, -50%);
    //     //     border-top: unset;
    //     //     border-radius: 100%;
    //     //     background: inherit;
    //     //     width: 80%;
    //     //     height: 80%;
    //     //     border: 2px solid #fff;
    //     // }
    // }
}

.ant-btn {
    transition: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: #052D57;
    transition: background-color 5000s ease-in-out 0s;
}