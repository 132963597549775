:root {
    --font-fallback: BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-sans;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Italic-VariableFont_wght.ttf') format('truetype');
    font-style: italic;
}
