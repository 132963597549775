.xxlSelect {
    &.ant-select {
        width: 100%;
        height: 60px;

        .ant-select-selector {
            border-width: 2px !important;
            border-color: #f2f7ff !important;
        }
        .ant-select-arrow {
            font-size: 20px;
        }

        .ant-select-selection-item {
            h5 {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: nowrap;
                font-weight: 700 !important;
                color: var(--ant-color-primary);
            }
        }
    }
}

.xxlSelectOptionTitle {
    margin: 0;
    padding: var(--ant-padding-xs);
    font-weight: 500 !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;

    position: relative;
    .annual-em {
        color: var(--ant-color-primary);
    }

    .save-tag {
        text-transform: uppercase;
        // position: absolute;
        // top: 50%;
        // margin-top: -10px;
        // right: var(--ant-padding-sm);
        background-color: #9CDE68;
        color: #fff;
        height: 20px;
        display: block;
        line-height: 20px;
        height: 20px;
        font-weight: 700;
        font-size: 12px;
        padding: 1px 6px 1px 6px;
        border-radius: var(--ant-border-radius-sm);
    }
}
