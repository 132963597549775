/** Custom Sign In Styles */

.ant-input-affix-wrapper {
    height: 52px;
    padding: 10px 16px;
}

.ant-input-group-wrapper:not(:has(.ant-input-affix-wrapper)) {
    .ant-input-wrapper {
        .ant-input {
            height: 52px;
            padding: 10px 16px;
        }
    }
}

.signup-input {
    height: 52px;
    padding: 10px 16px;
}
